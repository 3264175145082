import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { reduxForm, reset } from "redux-form";
import { connect } from "react-redux";

import Button from "../../components/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ModalComponent from "../../components/ModalComponent";

import CloseIcon from "@material-ui/icons/Close";
import CityFields from "./CityFields";

import { InputField, CustomInputField } from "./inputFields";

import { useTranslation } from "react-i18next";

const DialogComponent = ({ children, open, setOpen }) => {
	const classes = useStyles();

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<Dialog
			classes={{
				paper: classes.dialogPaper,
			}}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<div className={classes.iconsWrapper}>
				<CloseIcon
					onClick={() => handleClose()}
					className={classes.closeIcon}
				/>
				<DialogTitle className={classes.dialogTitle} id="form-dialog-title">
					Редактировать зону
				</DialogTitle>
			</div>
			<DialogContent className={classes.dialogContent}>
				{children}
			</DialogContent>
		</Dialog>
	);
};

let ModalEditForm = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	// Modal Form
	const [openModal, setOpenModal] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [sendData, setSendData] = useState(null);

	const [paidShipping, setPaidShipping] = useState(false);
	const [freeShipping, setFreeShipping] = useState(false);

	const handleClose = () => {
		setOpenModal(false);
		setIsSubmit(false);
	};

	const handleClickSubmit = () => {
		setOpenModal(false);
		setIsSubmit(true);
	};

	const { setOpen, handleSubmit, dispatch, data } = props;

	const handleButtonClick = (sendFormData) => {
		setSendData(sendFormData);
		setOpenModal(true);
		setIsLoading(true);
	};

	useEffect(() => {
		if (isLoading && isSubmit && !openModal) {
			console.log("Вы подтверждаете");

			props.onSubmit(sendData);

			if (props.valid) {
				dispatch(reset("ModalEditForm"));
				setOpen(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, isSubmit, openModal]);

	const initialValues = { ...data };

	return (
		<DialogComponent {...props}>
			<ModalComponent
				title={t("modals.confirmSave")}
				description={`Вы подтверждаете редактирование зоны?`}
				open={openModal}
				handleClose={handleClose}
				handleClickSubmit={handleClickSubmit}
			/>
			<form
				onSubmit={handleSubmit}
				autoComplete="off"
				initialValues={initialValues}
			>
				<div className={classes.zonaItemsWrapper}>
					<InputField
						name="name"
						title="Название зоны"
						type="text"
						placeholder="Введите название зоны"
						validMsg="Введите название"
					/>
					<CustomInputField
						name="deliveryItemId"
						title="ID товара доставки"
						type="text"
						popover={true}
						checkBox={false}
						popoverHiddenText="-"
						placeholder="Введите ID товара доставки"
						validMsg="Введите ID товара доставки"
					/>
				</div>

				<div className={classes.cityFieldsWrapper}>
					<CityFields
						className={classes.cityFields}
						initialValues={props.initialValues}
						paidShipping={paidShipping}
						freeShipping={freeShipping}
						setPaidShipping={setPaidShipping}
						setFreeShipping={setFreeShipping}
					/>
				</div>
				<div className={classes.buttonWrapper}>
					<Button
						fontSize={22}
						color={"#fff"}
						className={classes.button}
						text="Сохранить"
						handleClick={handleSubmit((data) => handleButtonClick(data))}
					/>
				</div>
			</form>
		</DialogComponent>
	);
};

const useStyles = makeStyles((theme) => ({
	dialogPaper: {
		width: "100%",
		maxWidth: 1057,
		padding: 24,
		[theme.breakpoints.down("1050")]: {
			padding: 16,
			margin: "0 16px",
			marginTop: 100,
		},
		[theme.breakpoints.down("600")]: {
			margin: "0 10px",
			marginTop: 100,
		},
	},
	dialogTitle: {
		padding: 0,
		"& h2": {
			fontSize: 16,
			fontWeight: "normal",
			lineHeight: "25px",
		},
	},
	dialogContent: {
		[theme.breakpoints.down("600")]: {
			padding: 0,
		},
	},
	iconsWrapper: {
		display: "flex",
	},
	closeIcon: {
		marginRight: 16,
		color: "#476282",
		"&:hover": {
			color: "black",
			cursor: "pointer",
		},
	},
	cityFieldsWrapper: {
		maxWidth: 672,
		margin: "0 auto",
	},
	cityFields: {
		display: "flex",
		flexWrap: "wrap",
		paddingTop: 20,
		"& > div:nth-child(2)": {
			marginRight: 0,
		},
	},
	zonaItemsWrapper: {
		display: "flex",
		maxWidth: 672,
		margin: "0 auto",
		alignItems: "center",
		marginTop: 35,
		paddingBottom: 20,
		borderBottom: "1px solid #ccc",
		[theme.breakpoints.down("1050")]: {
			flexWrap: "wrap",
			marginTop: 16,
			paddingBottom: 16,
		},
		[theme.breakpoints.down("600")]: {
			marginTop: 0,
		},
	},
	buttonWrapper: {
		margin: "0 auto",
		marginTop: 30,
		textAlign: "center",
		[theme.breakpoints.down("600")]: {
			marginTop: 0,
		},
	},
	button: {
		textTransform: "none",
		"& disabled": {
			color: "#476282",
			background: "#F1F3F4",
			textTransform: "capitalize",
		},
	},
}));

const mapStateToProps = (state, props) => {
	const data = props.data;

	if (data) {
		const { deliveryItemId, name, deliveryLimit, deliveryFreeLimit, delivery } =
			data;

		let initialValues = {
			name,
			delivery,
			deliveryLimit,
			deliveryItemId,
			deliveryFreeLimit,
		};

		return { initialValues };
	}
};

ModalEditForm = connect(
	mapStateToProps,
	null
)(
	reduxForm({
		form: "ModalEditForm",
		enableReinitialize: true,
	})(ModalEditForm)
);

export default ModalEditForm;
