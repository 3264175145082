import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";

import Tabs from "./tabs";

const SortMode = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Paper className={classes.modal}>
					<div className={classes.wrapper}>
						<div className={classes.title}>{t("navigation.productsOrder")}</div>
						<Tabs />
					</div>
				</Paper>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles({
	modal: {
		width: "100%",
		height: "auto",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		background: "white",
		borderRadius: 10,
	},
	wrapper: {
		width: "100%",
		padding: 16,
		paddingTop: 24,
		paddingBottom: 16,
		boxSizing: "border-box",
	},
	title: {
		fontSize: 22,
		fontFamily: "Roboto",
		fontWeight: "bold",
		lineHeight: 1,
		letterSpacing: "0.003em",
	},
});

export default SortMode;
