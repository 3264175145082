/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { reduxForm } from "redux-form";
import { required, numericality } from "redux-form-validators";

import Button from "../../../../components/Button";
import Typography from "@material-ui/core/Typography";
import { InputField } from "../../../../components/UIform/inputField";
import ModalComponent from "../../../../components/ModalComponent";
import DialogComponent from "../../DialogComponent";

import { useFormStyles } from "./styles";
import { useTranslation } from "react-i18next";

let FormDialog = (props) => {
	const classes = useFormStyles();
	const { t } = useTranslation();

	const [openModal, setOpenModal] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [data, setData] = useState(null);

	const { handleSubmit, initialValues } = props;

	useEffect(() => {
		props.initialize(initialValues);
	}, []);

	useEffect(() => {
		if (isSubmit && !openModal) {
			props.onSubmit(data);
			props.handleClose();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmit, openModal]);

	const handleClose = () => {
		setOpenModal(false);
		setIsSubmit(false);
	};

	const handleClickSubmit = () => {
		setOpenModal(false);
		setIsSubmit(true);
	};

	const submitForm = (data) => {
		const { valid } = props;
		setData(data);

		if (valid) {
			setOpenModal(true);
		}
	};

	return (
		<DialogComponent {...props}>
			<ModalComponent
				title={t("modals.confirmSave")}
				description="Вы подтверждаете сохранение изменений?"
				open={openModal}
				handleClose={handleClose}
				handleClickSubmit={handleClickSubmit}
			/>
			<form
				className={classes.modalForm}
				onSubmit={handleSubmit}
				autoComplete="off"
				initialValues={initialValues}
			>
				<div className={classes.discountRadios}>
					<div className={classes.discountInput} style={{ height: "100%" }}>
						<label
							htmlFor="pickupDiscount"
							className={classes.discountInputWrap}
						>
							<Typography className={classes.discountTitle}>Скидка</Typography>
						</label>
						<div className={classes.discountHidden}>
							<InputField
								title="Процент скидки"
								placeholder="Введите процент скидки"
								name="pickupDiscount"
								className={classes.inputField}
								// onChange={(e) => setPickupDiscount(e.target.value)}
								validate={[
									required({ msg: "Поле обязательно для заполнения" }),
									numericality({
										int: true,
										"<=": 100,
										msg: {
											"<=": "Значение должно быть меньше или равно {count}",
											int: "Необходимо целые числа",
										},
									}),
								]}
							/>
						</div>
					</div>
				</div>

				<div className={classes.buttonWrapper}>
					<Button
						className={classes.button}
						fontSize={22}
						color={"#fff"}
						load={props.load}
						text="Сохранить"
						handleClick={handleSubmit((data) => {
							submitForm(data);
						})}
					/>
				</div>
			</form>
		</DialogComponent>
	);
};

FormDialog = reduxForm({
	form: "ModalForPickup",
	enableReinitialize: true,
})(FormDialog);

export default FormDialog;
