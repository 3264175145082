import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ModalComponent from "../../components/ModalComponent";

import Tabs from "./Tab";
import TabApp from "./TabApp";
import TabProduct from "./TabProduct";
import TabOrderReviews from "./TabOrderReviews";
import FilterPanel from "./FilterPanel";

const Reviews = (props) => {
	const {
		classes,
		handleKey,
		deleteReview,
		isActiveReviews,
		isActiveApps,
		isActiveOrderReviews,
	} = props;
	const [tab, setTab] = React.useState(0);
	const { t } = useTranslation();

	// Modal Form
	const [openModal, setOpenModal] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [categorieName, setCategorieName] = useState(null);

	const handleClose = () => {
		setOpenModal(false);
		setIsSubmit(false);
	};

	const handleClickSubmit = () => {
		setOpenModal(false);
		setIsSubmit(true);
	};

	useEffect(() => {
		if (tab === 0) handleKey(0);
		if (tab === 1) handleKey(1);
		// if (tab === 2) handleKey(2);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab]);

	const handleButtonClick = (name) => {
		setOpenModal(true);
		setIsLoading(true);
		setCategorieName(name);
	};

	useEffect(() => {
		if (isLoading && isSubmit && !openModal) {
			console.log("Вы подтверждаете", categorieName);

			deleteReview(categorieName);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, isSubmit, openModal]);

	const TabsContent = (props) => (
		<div className={classes.tabContainer}>
			{((tab === 0 && props.isActiveOrderReviews) ||
				(props.isActiveOrderReviews && !props.isActiveApps)) && (
				<TabOrderReviews {...props} handleButtonClick={handleButtonClick} />
			)}
			{((tab === 1 && props.isActiveReviews) ||
				(props.isActiveReviews && !props.isActiveApps)) && (
				<TabProduct {...props} handleButtonClick={handleButtonClick} />
			)}
			{/* {((tab === 2 && props.isActiveApps ) || (!props.isActiveReviews && props.isActiveApps)) && <TabApp {...props} />} */}
		</div>
	);

	const isMin600 = useMediaQuery("(max-width: 599px)");
	const isMax600 = useMediaQuery("(min-width: 600px)");

	return (
		<>
			{isMin600 && (
				<Typography className={classes.titleMobile} variant="h1">
					{t("navigation.reviews")}
				</Typography>
			)}
			<Grid
				className={classes.container}
				direction={"row"}
				container
				alignItems={"center"}
			>
				<ModalComponent
					title={t("modals.confirmSave")}
					description={`Вы подтверждаете удаление отзыва ${categorieName} ?`}
					open={openModal}
					handleClose={handleClose}
					handleClickSubmit={handleClickSubmit}
				/>
				<Grid item xs={12}>
					{isMax600 && (
						<Typography className={classes.title} variant="h1">
							{t("navigation.reviews")}
						</Typography>
					)}
					<Tabs
						isActiveApps={isActiveApps}
						isActiveReviews={isActiveReviews}
						isActiveOrderReviews={isActiveOrderReviews}
						value={tab}
						setValue={setTab}
					/>
					<FilterPanel
						isActiveApps={isActiveApps}
						isActiveReviews={isActiveReviews}
						{...props}
						tab={tab}
					/>
					<TabsContent
						isActiveApps={isActiveApps}
						isActiveReviews={isActiveReviews}
						{...props}
					/>
				</Grid>
			</Grid>
		</>
	);
};

const styles = (theme) => ({
	container: {
		padding: 16,
		borderRadius: 5,
		background: "white",
		border: "0.5px solid #DADCE0",
	},
	title: {
		fontSize: 22,
		fontWeight: "bold",
		lineHeight: "26px",
	},
	titleMobile: {
		marginTop: 16,
		marginBottom: 8,
		fontSize: 22,
		fontWeight: "bold",
		lineHeight: "26px",
	},
	tabContainer: {
		[theme.breakpoints.down("600")]: {
			"& > div > .MuiGrid-root": {
				display: "flex",
				justifyContent: "center",
				alignContent: "center",
			},
			"& > div > .MuiGrid-root > div": {
				marginBottom: "16px",
				marginRight: 6,
			},
		},
		[theme.breakpoints.down("376")]: {
			"& > div > .MuiGrid-root > div > div.MuiGrid-root > div": {
				width: "calc(100% - 16px)",
				// marginRight: 0,
				// minWidth: 297,
			},
			"& > div > .MuiGrid-root": {
				// justifyContent: 'inherit',
				// alignContent: 'inherit',
			},
			"& > div > .MuiGrid-root > div": {
				// width: '100%',
			},
		},
	},
});

export default withStyles(styles, { withTheme: true })(Reviews);
