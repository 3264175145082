/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonCreate from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import ModalCreateForm from "./ModalCreateForm";
import ModalEditForm from "./ModalEditForm";
import ModalComponent from "../../components/ModalComponent";

import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";

import { checkFields } from "./other";

const ZonesForms = ({ saveZones, editZone, deleteZone, deliveryZone }) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const [openCreateForm, setOpenCreateForm] = useState(false);
	const [openEditForm, setOpenEditForm] = useState(false);
	// const [isCreating, setIsCreating] = useState(false);
	// const [isEditing, setIsEditing] = useState(false);

	const [editData, setEditData] = useState();
	const [itemId, setItemId] = useState(null);
	const [dataParams, setDataParams] = useState([]);

	const isZonas = dataParams.length > 0;

	useEffect(() => {
		if (deliveryZone) {
			if (dataParams.length === 0) {
				setDataParams(deliveryZone);
			}
		} else {
			setDataParams([]);
		}
	}, [deliveryZone]);

	// Modal Form
	const [openModal, setOpenModal] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [filtredDataParams, setFiltredDataParams] = useState(null);

	const handleClose = () => {
		setOpenModal(false);
		setIsSubmit(false);
	};

	const handleClickSubmit = () => {
		setOpenModal(false);
		setIsSubmit(true);
	};

	useEffect(() => {
		if (isLoading && isSubmit && !openModal) {
			console.log("Вы подтвердили");

			setDataParams(filtredDataParams);
			deleteZone(filtredDataParams, itemId);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmit, openModal]);

	const addZonaInput = (data) => {
		data = checkFields(data);

		console.log("check data --->>>", data);

		const defaultData = dataParams.filter((item) => item !== undefined);
		let zonesData = [];

		let id = dataParams.length;
		let newDataParams = [...defaultData];
		newDataParams[id] = data;

		for (let key in newDataParams) {
			zonesData.push(newDataParams[key]);
		}

		if (zonesData) {
			console.log("zonesData", zonesData);
			setDataParams(newDataParams);
			saveZones(zonesData);
		}
	};

	const editZonaInput = (data) => {
		// console.log('--- edit ----');

		let newData = [];

		data = checkFields(data);

		console.log("edit checked data -->>> ", data);

		for (let key in dataParams) {
			if (dataParams[key]) {
				if (key == itemId) {
					console.log("==== itemId ===", itemId);
					newData[itemId] = data;
				} else {
					newData.push(dataParams[key]);
				}
			}
		}

		data = {
			...data,
			delivery: !data.delivery ? "0" : data.delivery,
		};

		if (data) {
			setDataParams(newData);
			editZone(data, itemId);
		}
	};

	const createNewZonaItem = () => {
		setOpenCreateForm(true);
		// setIsCreating(true);
	};

	const deleteZonaItem = (id) => {
		// console.log('id', id)
		const zonesData = [];
		let indexId;

		const filtredDataParams = dataParams.filter((_, index) => {
			// console.log('ids, index', ids, index);

			if (Number(index) === Number(id)) {
				setItemId(index);
				indexId = index;
			}

			return Number(index) !== Number(id);
		});

		for (let key in filtredDataParams) {
			zonesData.push(filtredDataParams[key]);
		}

		setFiltredDataParams(zonesData);
		setItemId(indexId);
		setOpenModal(true);
		setIsLoading(true);
	};

	const editZonaItem = (id) => {
		const data = dataParams.find((_, index) => index === id);

		setEditData(data);
		setItemId(id);
		// setIsEditing(true);

		setOpenEditForm(true);
	};

	const ZoneHeader = ({ title }) => (
		<div className={classes.zonaHeader}>
			<Typography className={classes.zonaTitle} component="h6">
				{title}
			</Typography>
			<ButtonCreate
				className={classes.zonaButtonCreate}
				variant="contained"
				onClick={() => createNewZonaItem()}
			>
				Создать
			</ButtonCreate>
		</div>
	);

	const NoZone = ({ title }) => (
		<div className={classes.zonaFieldsTitleWrapper}>
			<Typography className={classes.zonaFieldsTitle} component="h6">
				{title}
			</Typography>
		</div>
	);

	const ZoneContent = ({ dataParams }) => (
		<div className={classes.zonaContent}>
			{dataParams.map((item, index) => {
				return (
					<div className={classes.zonaItemWrapper} key={index}>
						<div className={classes.zonaItemTitle}>{item.name}</div>
						<div className={classes.zonaItemIcons}>
							<EditIcon
								onClick={() => editZonaItem(index)}
								className={classes.zonaEditIcon}
							/>
							<DeleteOutlineOutlinedIcon
								onClick={() => deleteZonaItem(index)}
								className={classes.zonaTrashIcon}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);

	return (
		<div className={classes.zonaWrapper}>
			{!!String(itemId) && (
				<ModalComponent
					title={t("modals.confirmSave")}
					description={`Вы подтверждаете удаление ${
						!!String(itemId) && dataParams[itemId] && dataParams[itemId].name
					} зоны?`}
					open={openModal}
					handleClose={handleClose}
					handleClickSubmit={handleClickSubmit}
				/>
			)}
			<ModalCreateForm
				open={openCreateForm}
				setOpen={setOpenCreateForm}
				onSubmit={addZonaInput}
			/>
			<ModalEditForm
				data={editData}
				open={openEditForm}
				setOpen={setOpenEditForm}
				onSubmit={editZonaInput}
			/>
			<ZoneHeader title="Зоны" />
			<div className={classes.zonaFields}>
				{!isZonas && <NoZone title="Пока нет созданных зон" />}
				{isZonas && <ZoneContent dataParams={dataParams} />}
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	zonaButtonCreate: {
		width: 109,
		height: 36,
		fontSize: 14,
		lineHeight: "14px",
		background: "#E9F1FC",
		color: "#326DFF",
		boxShadow: "none",
		textTransform: "capitalize",
		"&:hover": {
			boxShadow: "none",
			background: "#326DFF",
			color: "#E9F1FC",
		},
	},

	zonaHeader: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		height: 40,
	},
	zonaWrapper: {
		position: "relative",
		marginTop: 16,
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "column",
		padding: "24px 16px",
		border: "1px solid #DADCE0",
		borderRadius: 5,
		minHeight: 240,
	},
	zonaTitle: {
		fontSize: 18,
		fontWeight: "bold",
		lineHeight: "21px",
		color: "black",
	},
	zonaFields: {
		width: "100%",
	},
	zonaFieldsTitleWrapper: {
		width: 328,
		height: 18,
		position: "absolute",
		top: "calc(50% - 9px)",
		left: "calc(50% - 150px)",
		textAlign: "center",
	},
	zonaFieldsTitle: {
		fontSize: 15,
		lineHeight: "18px",
		color: "#476282",
	},

	zonaItemWrapper: {
		display: "flex",
		height: 57,
		width: "100%",
		padding: "0 16px",
		marginBottom: 16,
		boxSizing: "border-box",

		boxShadow: "0px 14px 23px rgba(232, 228, 228, 0.5)",
		background: "white",
		borderRadius: 5,
	},
	zonaContent: {
		marginTop: 14,
	},
	zonaItemTitle: {
		width: "50%",
		fontSize: 16,
		fontWeight: 500,
		lineHeight: "57px",
		color: "black",
	},
	zonaItemIcons: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		width: "50%",
		lineHeight: "57px",
	},
	zonaEditIcon: {
		marginRight: 16,
		color: "#476282",
		"&:hover": {
			color: "rgb(50, 109, 255)",
		},
	},
	zonaTrashIcon: {
		color: "#476282",
		"&:hover": {
			color: "rgb(50, 109, 255)",
		},
	},
}));

export default ZonesForms;
