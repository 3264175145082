import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink,
	withRouter,
} from "react-router-dom";

import { useTranslation } from "react-i18next";

import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import * as ROUTERS from "../../../constants/routes";
import ProductsRoute from "./products/productsRoute";
import Categories from "./categories";
import Products from "./products";

const AntTabs = withStyles({
	root: {
		minHeight: 32,
		borderBottom: "1px solid inherit",
	},
	indicator: {
		maxWidth: 24,
		height: 2,
		backgroundColor: "#326DFF",
	},
})(Tabs);

const AntTab = withStyles((theme) => ({
	root: {
		color: "#476282",
		minWidth: 30,
		minHeight: 32,
		marginRight: 30,
		fontFamily: "Poppins, sans-serif",
		fontSize: 16,
		fontWeight: 600,
		textTransform: "inherit",
		textAlign: "left",
		padding: 0,
		"&:last-child": {
			marginRight: 0,
		},
		"&:hover": {
			color: "#326DFF",
			opacity: 1,
		},
		"&$selected": {
			color: "#326DFF",
			fontWeight: 600,
			textAlign: "left",
		},
		"&:focus": {
			color: "#326DFF",
		},
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

const TabsComponent = (props) => {
	const { t } = useTranslation();

	const classes = useStyles();
	const { saleFirstOrder, promoCodes, discontProps, adminPermission } = props;
	const [value, setValue] = React.useState(0);
	const baseUrl = props.match.url;

	const categories = useSelector((state) => state.shops.categories);
	const firstCategory = Object.keys(categories)[0];

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Router>
			<div className={classes.tabsComponent}>
				<div className={classes.tabHeader}>
					<div className={classes.tabLinks}>
						<NavLink
							to={ROUTERS.SORT_MODE_CATEGORIES}
							className={classes.tabLink}
						>
							{t("navigation.categories")}
						</NavLink>
						<NavLink
							to={`/page/menu/products/sort-mode/products`}
							className={classes.tabLink}
						>
							{t("navigation.products")}
						</NavLink>
					</div>
				</div>
				<div>
					<Switch>
						<Route
							path={`/page/menu/products/sort-mode/categories`}
							component={Categories}
						/>
						<Route
							path={"/page/menu/products/sort-mode/products/category/:id"}
							component={Products}
						/>
						<Route
							path={`/page/menu/products/sort-mode/products`}
							component={ProductsRoute}
						/>
					</Switch>
				</div>
			</div>
		</Router>
	);
};

const useStyles = makeStyles((theme) => ({
	tabsComponent: {},
	tabs: {
		marginTop: 20,
		minHeight: "auto",
	},
	tab: {
		minWidth: "auto",
		minHeight: "auto",
		padding: 0,
		paddingRight: 30,
		fontFamily: "Poppins, sans-serif",
		fontWeight: 600,
		fontSize: 16,
		lineHeight: "28px",
		textTransform: "inherit",
		color: "#476282",
		"&:last-child": {
			// background: 'red'
		},
		"&.MuiTouchRipple-root": {},
	},
	tabHeader: {
		margin: "16px 0 26px 10px",
	},
	discountsAndPromotions: {},
	tabLinks: {
		display: "flex",
	},
	tabLink: {
		position: "relative",
		fontFamily: "Poppins, sans-serif",
		fontSize: 16,
		fontWeight: 600,
		lineHeight: "28px",
		marginRight: 26,
		textDecoration: "none",
		color: "#476282",
		"&.active": {
			color: "#326DFF",
			"&::before": {
				content: '""',
				position: "absolute",
				bottom: 0,
				left: 0,
				width: "50%",
				height: 2,
				background: "#326DFF",
			},
		},
	},
}));

export default withRouter(TabsComponent);
