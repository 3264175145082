import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { reduxForm } from "redux-form";

import Button from "../../components/Button";
import CityFields from "./CityFields";
import ModalComponent from "../../components/ModalComponent";

import { useTranslation } from "react-i18next";

let CityForm = ({ initialValues, saveCity, handleSubmit }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [openModal, setOpenModal] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [dataCityForm, setDataCityForm] = useState(null);
	const [freeShipping, setFreeShipping] = useState(false);
	const [paidShipping, setPaidShipping] = useState(false);

	const handleClose = () => {
		setOpenModal(false);
		setIsSubmit(false);
	};

	const handleClickSubmit = () => {
		setOpenModal(false);
		setIsSubmit(true);
	};

	const handleCityForm = (data) => {
		setOpenModal(true);
		if (data) {
			setDataCityForm(data);
		}
	};

	useEffect(() => {
		if (isSubmit && !openModal) {
			console.log("Вы подтвердили");
			console.log("dataCityForm", dataCityForm);

			saveCity(dataCityForm);
		}
	}, [isSubmit, openModal]);

	return (
		<form
			onSubmit={handleSubmit}
			autoComplete="off"
			initialValues={initialValues}
		>
			<ModalComponent
				title={t("modals.confirmSave")}
				description={`Вы подтверждаете сохранение изменений`}
				open={openModal}
				handleClose={handleClose}
				handleClickSubmit={handleClickSubmit}
			/>
			<CityFields
				initialValues={initialValues}
				className={classes.cityFields}
				freeShipping={freeShipping}
				paidShipping={paidShipping}
				setFreeShipping={setFreeShipping}
				setPaidShipping={setPaidShipping}
			/>
			<div className={classes.buttonWrapper}>
				<Button
					fontSize={22}
					color={"#fff"}
					className={classes.button}
					text="Сохранить"
					handleClick={handleSubmit((data) => handleCityForm(data))}
				/>
			</div>
		</form>
	);
};

const useStyles = makeStyles((theme) => ({
	cityFields: {
		display: "flex",
		flexWrap: "wrap",
		marginTop: 8,
		padding: "8px 16px 24px 16px",
		border: "1px solid #DADCE0",
		borderRadius: 5,
	},
	buttonWrapper: {
		textAlign: "center",
	},
	button: {
		marginTop: 30,
		textTransform: "none",
	},
}));

CityForm = reduxForm({ form: "CityForm", enableReinitialize: true })(CityForm);

export default CityForm;
